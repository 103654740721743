<template>
  <div class="mobile-order">
    <div class="mobile-order-title">
      <h4>My Orders</h4>
      <p>Your orders and their status are listed below</p>
    </div>
    
    <div class="mobile-order-body">
      <div class="dashboard-card mobile-dashboard-item" v-for="item in orders" :key="item.id">
        <div class="item-line">
          <div class="item-info">
            <h5>Service</h5>
            <h6>{{item.orderTitle}}</h6>
          </div>
          <div class="item-info">
            <h5>Order ID</h5>
            <h6>{{item.orderNo}}</h6>
          </div>
        </div>
        <div class="item-line">
          <div class="item-info">
            <h5>Date</h5>
            <h6>{{item.orderDateLabel}}</h6>
          </div>
          <div class="item-info">
            <h5>Status</h5>
              <span v-show="item.orderStatus === 3" class="lab-badge lab-badge-minted-elegance">Completed</span>
              <span v-show="item.orderStatus === 1" class="lab-badge lab-badge-just-peachy">Pending</span>
              <span v-show="item.orderStatus === 2" class="lab-badge lab-badge-lago-blue">Processing</span>
              <span v-show="item.orderStatus === 4" class="lab-badge lab-badge-mellow-melon">Cancelled</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
    }
  },
  methods: {
    fetchOrders() {
      this.$store.dispatch('orders/fetchOrders', {});
    },
  },
  computed: {
    orders() {
      return this.$store.getters['orders/getOrders'];
    },
  },
  created() {
    this.fetchOrders();
  },
};
</script>

<style scoped lang="scss">
.mobile-ticket-title {
  padding: 0.5rem 1.5rem;

  h4 {
    color: var(--lab-carbon-fiber);
  }
  p {
    color: var(--lab-stieglitz-silver);
  }
}
</style>
